"use client"

import { useTheme } from "@mui/material/styles"
import type { ReactNode } from "react"

import { Paper } from "@/components/ui/surfaces/Paper"

import { SiteFooter } from "@/app/_components/SiteFooter"
import { Stack } from "@/components/ui/layout/Stack"

import { LOGO_SIZE_FACTOR, LogoIcon, LogoText } from "@/app/_components/Logo"
import { Box } from "@/components/ui/layout/Box"

export default function AuthLayout({ children }: { children: ReactNode }) {
  const theme = useTheme()

  return (
    <main className="flex flex-col h-[100vh] w-full">
      <Box flexGrow={1} />
      <div className="flex justify-center h-full">
        <Paper
          variant="outlined"
          elevation={0}
          sx={{
            width: "480px",
            padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
            borderRadius: `${theme.shape.borderRadius * 2}px`,
            bgcolor: theme.palette.background.default,
          }}
          className="flex flex-col justify-center self-center"
        >
          <Stack spacing={theme.spacing(2.5)} className="h-full">
            <Stack
              className="self-center"
              spacing={theme.spacing(1)}
              direction="row"
              alignItems="center"
            >
              <LogoIcon height={theme.spacing(3 * LOGO_SIZE_FACTOR)} />
              <LogoText height={theme.spacing(3)} />
            </Stack>
            {children}
          </Stack>
        </Paper>
      </div>
      <SiteFooter />
    </main>
  )
}
